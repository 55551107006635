/* ## Box Sizing
--------------------------------------------- */

html,
input[type="search"]{
	-webkit-box-sizing: border-box;
	-moz-box-sizing:    border-box;
	box-sizing:         border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	transition: 0s;
}

/* # Defaults
---------------------------------------------------------------------------------------------------- */

/* ## Typographical Elements
--------------------------------------------- */

html {
	font-size: 62.5%; /* 10px browser default */
	overflow-x: hidden;
}

body {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: $light-primary;
	color: $dark-primary;
  font-family: $font-body;
	font-size: 1.4rem;
	-webkit-font-smoothing: antialiased;
	font-weight: 400;
	line-height: 1.2;
	margin: 0;
	overflow-x: hidden;
}

body.ie {
	display: block;
}

a,
button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition:    all 0.1s ease-in-out;
	-ms-transition:     all 0.1s ease-in-out;
	-o-transition:      all 0.1s ease-in-out;
	transition:         all 0.1s ease-in-out;
}


a,
a:active,
a:visited,
-webkit-link {
	color: $link-primary;
  text-decoration: none;
}

a:hover,
a:focus {
	color: $link-secondary;
	text-decoration: none;
}

p {
	line-height: 1.36;
  letter-spacing: 0.02rem;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;

	&:not(:last-of-type) {
		margin-bottom: 2rem;
	}
}

p.bigger {
	font-size: 1.6rem;
}

ol,
ul {
	margin: 0;
	padding: 0;

	li {
		list-style-type: none;
	}

}

.single-post main section ul li {
  list-style-type: disc;
  margin-left: 2rem;
}

.single-post main section ol li:not(.step) {
  list-style-type: decimal;
  margin-left: 2rem;
}

hr {
	clear: both;
	margin: 1em 0;
	margin-bottom: 3.25rem;
	border-collapse: collapse;
	max-width: 27%;
	border-color: #000;
	border-width: 0;
	border-bottom-width: 3px;
}

b,
strong {
	font-weight: 700;
}

blockquote,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 40px;
}

blockquote::before {
	content: "\201C";
	display: block;
	font-size: 30px;
	font-size: 3rem;
	height: 0;
	left: -20px;
	position: relative;
	top: -10px;
}


html {
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
}

/* ## Headings
--------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6,
.xlarge,
.large,
.medium {
	font-weight: 900;
	margin-top: 0;
	font-family: $font-heading;
	letter-spacing: 0.15rem;
	line-height: 1.375;
	text-transform: uppercase;
}

h1,
.xlarge {
	font-family: $font-body;
	font-size: 2.8rem;
	letter-spacing: 0;
	font-weight: 700;
	line-height: 1.1;

	&:not(:only-child) {
		margin-bottom: 2.8rem;
	}
}

h2,
.large {
	font-size: 2.4rem;
	margin-bottom: 2.4rem;
}

h3 {
	font-size: 2.25rem;
	margin-bottom: 2rem;
	text-transform: capitalize;
}

h4 {
	font-size: 2rem;
	margin-bottom: 2.75rem;
}

h5 {
	font-size: 1.8rem;
}

h6 {
	font-size: 1.6rem;
}

/* ## Objects
--------------------------------------------- */

embed,
iframe,
img,
object,
video,
.wp-caption {
	max-width: 100%;
}

img {
	height: auto;
}

figure {
	margin: 0;
}

/* ## Tables
--------------------------------------------- */

table {
	border-collapse: collapse;
	border-spacing: 0;
	line-height: 2;
	margin-bottom: 40px;
	width: 100%;
	word-break: break-word;
}

td,
th {
	text-align: left;
}

td {
	padding: 0;
	line-height: 1.8;
}

th {
	padding: 0.6rem;
}


/* # DRY
------------------------------------------------------------------------------------------------------------------------------*/

.alignleft {
	text-align: left;
}

.aligncenter {
	text-align: center;
}

img.aligncenter {
	display: block;
	margin: auto;
}

.alignright {
	text-align: right;
}

.color-lightgreen {
	color: $accent-primary;
}

#page-hero {
	background: #333;
	position: relative;
	text-align: center;
	background-position: center;
	background-size: cover;
	color: $light-primary;

	h1,
	.xlarge {
		text-shadow: 0px 0px 10px rgba($grey-primary, 0.5);
	}

	.look-down {
		position: absolute;
		bottom: 2rem;
		left: calc(50% - 1rem);
		font-size: 4rem;
		opacity: 0;
	}
}

.text-light {
	color: #fff;
	background-color: #333;
}

main {
	position: relative;
	flex: 1;
	& > .content-wrapper {
		& > section {
			background: #fff;
	    background-size: cover;
	    background-position: center;
			& > .wrap {
				padding: 6rem 2rem;
			}
		}
	}
	#sidebar {

		& > .wrap {
			padding: 10rem 8rem;
		}
	}
}

#newsletter {
	text-align: center;
	background-color: $accent-primary;

	h2 {
		margin-bottom: 0;
	}

	input[type="submit"] {
		font-weight: 700;
	}
}

@media screen and (--small) {
	h1,
	.xlarge {
		font-size: 4.4rem;
		margin-bottom: 3.2rem;
	}
	h2,
	.large {
		font-size: 2.7rem;
		margin-bottom: 3rem;
	}
	main {
		& > .content-wrapper {
			& > section {
				& > .wrap {
					lost-center: 768px;
					padding: 8rem 2rem;
				}
			}
		}
	}
}

@media screen and (--medium) {
	body {
		font-size: 1.6rem;
	}
	h1,
	.xlarge {
		font-size: 5.4rem;
		margin-bottom: 4.2rem;
	}
	h2,
	.large {
		font-size: 3.0rem;
		margin-bottom: 4rem;
	}
	#page-hero {
		.wrap {
			padding: 14rem 2rem;
		}
	}
	main {
		& > .content-wrapper {
			& > section {
				& > .wrap {
					lost-center: 768px;
					padding: 10rem 2rem;
				}
			}
		}
	}
	#newsletter {

		.sign-up-container {

			form {
				lost-center: 540px;

				 p {
					 lost-utility: clearfix;

					 label {

						 &.email-container {
							 lost-column: 2/3;
						 }

						 &.button-container {
							 lost-column: 1/3;
						 }

						 input {
							 margin: 0;
							 height: 4rem;
						 }
					 }
				 }
			}
		}
	}
}

@media screen and (--medium-large) {
	h2,
	h1:not(:only-child).large {
		margin-bottom: 4rem;
	}
	main {
		& > .content-wrapper {
			& > section {
				& > .wrap {
					lost-center:740px;
				}
			}
		}
	}
}

@media screen and (--large) {
	body {
		font-size: 1.75rem;
	}

}
