.blog,
.archive {

  .tease-post {
    padding-bottom: 4rem;
    lost-utility: clearfix;

    .tease-image-container {
      position: relative;
      min-height: 80vw;
      margin-bottom: 2rem;

      .image {
        position: absolute 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }

    .tease-content-container {

      .tease-title {
        margin-bottom: 0;
        a {
          color: $dark-primary;
        }
      }

      .tease-date {
        color: $grey-tertiary;
      }

      .tease-excerpt {
        margin-top: 2rem;
      }
    }
  }

  .pagination {
    text-align: center;
    font-size: 2rem;

    .next,
    .prev,
    li {
      display: inline-block;
      padding: 1rem 0.5rem;
    }

    ul {
      display: inline-block;
    }
  }
}

.archive {

  #page-hero {
    background-image:  url('/wp-content/themes/jona/dist/img/j-bg-6.jpg');
  }

  .tease-post {

    .tease-image-container {
      display: none;
    }
  }
}

@media screen and (--small) {
  .blog {

    #posts {

      .wrap {
        lost-utility: clearfix;
      }

      .tease-post {
        lost-column: 1/2;

        .tease-image-container {
          min-height: 30vw;
        }
      }
    }
  }
}

@media screen and (--medium) {
  .blog {

    #posts {

      .wrap {
        lost-utility: clearfix;
        lost-center: 1100px;
      }

      .tease-post {
        lost-column: 1/3 3 5px;
        min-height: 600px;

        .tease-image-container {
          min-height: 20rem;
        }
      }
    }
  }
}

@media screen and (--medium-large) {
  .blog {

    #posts {

      .tease-post {
        lost-column: 1/3;
      }
    }
  }
}
