header {
  position: relative;
  width: 100vw;
  padding: 0.5rem 2rem;
  z-index: 1;
  color: $menu-primary;
  background: $accent-primary;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .top {
    background: $accent-primary;

    .title-area {
      width: auto;
      text-align: center;
      .logo {
        font-family: $font-cursive;
        text-align: center;
        .logo-link {
          img {
            height: 4rem;
          }
        }
      }

      .description {
        font-family: $font-cursive;
        font-weight: bold;
        font-style: italic;
      }
    }

    .contact-area {
      display: none;

      a {

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .nav-main {
    margin-left: auto;

    .fa {
      color: $grey-primary;
    }
    ul {
      background: $grey-primary;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 2rem 2rem 3rem;
      display: none;
      border-bottom: 1px solid $grey-tertiary;
      li {
        text-align: center;
        list-style-type: none;
        a {
          display: inline-block;
          width: 10rem;
          padding: 1rem;
          border-bottom: 1px solid rgba($grey-tertiary, 0.3);
          color: $light-primary;
          text-transform: uppercase;

          &:visited {
            color: $light-primary;
          }

          &:hover,
          &:focus {
            color: $accent-primary;
          }
        }
      }
    }
  }
}

@media screen and (--medium) {
  header {
    padding: 0;
    background: $grey-primary;
    .wrapper {
      display: block;
      .top {
        padding: 2rem 6rem;
        display: flex;
        align-items: center;

        .contact-area {
          text-align: right;
          display: inline-flex;
          align-items: center;
          float: right;
          margin-left: auto;
          font-size: 1.2rem;

          a {
            color: $light-primary;

            &.social-link {
              padding-right: 1rem;
              font-size: 2rem;
            }

            &:not(.social-link) {
              padding: 1rem 0;
              padding-left: 0;
              padding-right: 2rem;
              margin-right: 2rem;
              border-right: 2px solid rgba($light-primary, 0.2);
            }
          }
        }
      }
      .nav-main {
        display: block;
        width: 100%;
        background-color: $grey-primary;
        text-align: center;
        .menu-toggle {
          display: none !important;
        }
        ul {
          &#menu-main {
            display: inline-block !important;
            width: auto;
          }
          &[id*="menu"] {
            position: relative;
            background: rgba(0,0,0,0);
            padding: 0;
            display: inline-block;
            border: none;
            li {
              display: inline-block;
              &.menu-item-has-children {
                position: relative;
                &:hover {
                  & > ul#menu-submenu {
                    opacity: 1;
                    display: inline-block;
                  }
                }
              }
              a {
                border: none;
                padding: 1.5rem 1rem;
                font-size: 1.6rem;
              }

              &.current_page_item {
                font-weight: 700;

                a {
                  color: $accent-primary;
                }
              }
            }
          }
          &#menu-submenu {
            opacity: 0;
            display: none;
            position: absolute;
            background: $light-primary;
            width: 20rem;
            li {
              width: 100%;
              a {
                text-align: center;
                width: 100%;
                padding: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (--medium-large) {
  header {

    .wrapper {

      .top {

        .contact-area {
          font-size: 1.5rem;

          a {

            &.social-link {
              padding-right: 1.5rem;
              font-size: 2.5rem;
            }

            &:not(.social-link) {
              padding: 1rem 0;
              padding-left: 2rem;
              padding-right: 2rem;
              margin-right: 2rem;
              border-right: 2px solid rgba($light-primary, 0.2);
            }
          }
        }
      }
    }
  }

  .ie header {

    .top {

      .title-area {

        .logo {

          .logo-link {

            img {
              height: 4rem;
              width: 22rem;
            }
          }
        }
      }
    }
  }
}
