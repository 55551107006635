a[class*="btn-"],
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.button {
	font-size: 1.4rem;
  font-family: $font-heading;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
	display: inline-block;
	line-height: 1.1;
  margin-top: 2.5rem;
  transition: 0.3s ease;
	border: 0;
  background: $accent-primary;
  color: #fff;
	cursor: pointer;
}

button:disabled,
button:disabled:hover,
input:disabled,
input:disabled:hover,
input[type="button"]:disabled,
input[type="button"]:disabled:hover,
input[type="reset"]:disabled,
input[type="reset"]:disabled:hover,
input[type="submit"]:disabled,
input[type="submit"]:disabled:hover {
	background-color: rgba(0,0,0,0);
	color: $grey-primary;
	border: 1px solid $grey-primary;
	cursor: not-allowed;
}


@media screen and (--medium) {
	a[class*="btn-"] {
		font-size: 1.7rem;
	}

	a.btn-std,
	a.btn-alt {
	  margin-top: 3.5rem;
	}
}
