.single-products {

  .product-main {

    .product-body {
      margin-top: 2rem;

      .description {
        padding-bottom: 4rem;
      }

      .features-group {

        .features {

          .feature {
            font-weight: bold;
            padding-bottom: 0.5rem;
          }
        }
      }

      .installation {
        margin-top: 4rem;

        h3 {
          margin-bottom: 3rem;
        }

        .step {
          position: relative;
          counter-increment: step-counter;
          text-indent: 2.5rem;
          margin-bottom: 3rem;

          &::before {
            position: absolute;
            top: -1.5rem;
            left: -2.5rem;
            content: counter(step-counter);
            color: $accent-primary;
            font-family: $font-heading;
            font-weight: bold;
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  .product-media-container,
  .product-body {

    .product-img-container {

      .slick-dots {
        bottom: 10px;
      }
    }

    .downloads {
      margin-top: 4rem;

      .icon-pdf {
        display: none;
      }

      .download-group {
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        .language {
          font-weight: bold;
          padding-bottom: 0.5rem;
        }

        .file {
          padding: 0.5rem 0;
        }
      }
    }
  }

  .product-media-container {

    .downloads {
      display: none;
    }
  }
}

@media screen and (--small) {
  .single-products {

    .product-main {

      .wrap {
        lost-center: 600px;
      }
    }
  }
}

@media screen and (--medium) {
  .single-products {

    .product-main {

      .wrap {
        lost-center: 900px;
      }
    }
  }
}

@media screen and (--medium-large) {
  .single-products {

    .product-main {

      .wrap {
        padding-top: 6rem;
        lost-center: 1400px;
        lost-utility: clearfix;
      }
    }

    .product-main {

      .product-media-container,
      .product-body {
        lost-column: 1/2 2 80px;
      }

      .product-media-container {

        .downloads {
          display: inline-block;
        }
      }

      .product-body {
        margin-top: 0;

        .downloads {
          display: none;
        }
      }
    }

    .product-media-container {

      .downloads {
        margin-top: 6rem;
        margin-left: 6rem;
      }
    }
  }
}

@media screen and (--large) {
  .single-products {

    .product-media-container {

      .downloads {
        position: relative;
        margin-left: 12rem;

        .icon-pdf {
          display: inline-block;
          position: absolute 0 -100px;
          width: 6rem;
        }
      }
    }
  }
}
