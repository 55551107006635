.about {
  text-align: center;

  #page-hero {
    background: #333;
    background-image:  url('/wp-content/themes/jona/dist/img/j-bg-3.jpg');
    background-position: center;
    background-size: cover;
  }

  .banner-photo {
    text-align: center;
    padding: 6rem 0;
  }

  #team {

    .members {
      text-align: left;

      .member {
        padding: 3rem 0;
        transition: 0.3s;

        .fa {
          display: none;
          transition: 0.3s;
        }

        .image-container {
          position: relative;
          min-height: 100vw;
          margin-bottom: 2rem;

          .image {
            position: absolute 0;
            height: 100%;
            width: 100%;
            background-color: $grey-primary;
            background-size: cover;
            background-position: center;
            background-image: url('/wp-content/themes/jona/dist/img/no-photo.png');
          }
        }

        .info-container {

          .info {

            .name {
              margin-bottom: 0rem;
            }

            .title {
              font-weight: 600;
              font-style: italic;
            }
          }
        }

        &:hover {
          background: $accent-primary;
          color: $light-primary;

          .fa {
            color: $accent-primary;
          }
        }
      }
    }
  }

  #testimonials {
    background-color: #333;
    background-image: url('/wp-content/themes/jona/dist/img/j-bg-7.png');
    background-position: center;
    background-size: cover;

    .icon-container {
      padding-bottom: 2rem;

      img {
        height: 6rem;
        width: auto;
      }
    }

    .testimonials-container {

      .testimonial {

        .name {
          margin-bottom: 0rem;
        }
      }
    }
  }

  #timeline {

    .timeline-container {
      position: relative;
      margin: 4rem 0;

      .line {
        position: absolute 0;
        height: 100%;
        width: 100%;

        &::before {
          position: absolute;
          content: "";
          width: .5rem;
          height: 91%;
          top: 1%;
          left: 1rem;
          background: $grey-tertiary;
        }
      }

      .happening {
        position: relative;
        padding-left: 4rem;
        padding-bottom: 4rem;
        text-align: left;
        color: $grey-tertiary;

        .date,
        .event,
        &::before {
          transition: 0.15s;
        }

        &::before {
          position: absolute;
          content: '';
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
          top: 0.25rem;
          left: -0.25rem;
          background: $light-primary;
          border: 0.5rem solid $grey-tertiary;
        }

        &.active {

          .date {
            transition-delay: 0.15s;
            transition-duration: 0.6s;
            color: $accent-primary;
          }

          .event {
            transition-delay: 0.3s;
            transition-duration: 1s;
            color: $dark-primary;
          }

          &::before {
            border-color: $accent-primary;
          }
        }
      }
    }
  }
}

@media screen and (--small) {
  .about {
    .banner-photo {
      padding: 10rem 0;
    }

    #team {

      .members {

        .member {
          lost-center: 540px;

          .image-container {
            min-height: 50vw;
            lost-center: 60%;
          }
        }
      }
    }

    #timeline {

      .timeline-container {
        clear: fix;
        lost-utility: clearfix;

        .line {

          &::before {
            left: 50%;
          }
        }

        .happening {
          lost-column: 1/2 1;
          padding-bottom: 6rem;

          &::before {
            left: -2.75rem;
          }

          &:nth-child(2n + 1) {
            float: left;
            text-align: right;
            padding-left: 0;
            padding-right: 4rem;

            &::before {
              left: auto;
              right: -3.25rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (--medium) {
  .about {

    .banner-photo {
      padding: 15rem 0;
    }

    #team {

      .wrap {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        lost-center: 800px;
      }

      .members {

        .member {
          position: relative;
          lost-center: 100%;
          lost-utility: clearfix;
          padding: 0;
          margin: 0;

          .fa {
            position: absolute;
            display: inline-block;
            left: calc(50% - 1.5rem);
            top: calc(50% - 3rem);
            z-index: 2;
            font-size: 7rem;
            transform: rotate(-90deg);
            color: #fff;
          }

          .image-container,
          .info-container {
            lost-column: 1/2 0 0;
            margin: 0 !important;
          }

          .info-container {
            padding: 3rem;
          }

          .image-container {
            height: 100%;
            position: absolute;
            left: 50%;
            min-height: 0;
            max-width: none;
          }

          &:nth-child(2n + 1) {

            .fa {
              left: calc(50% - 2.5rem);
              transform: rotate(90deg);
            }

            .image-container {
              left: 0;
            }

            .info-container {
              float: right;
            }
          }
        }
      }
    }
  }
}

@media screen and (--medium-large) {
  .about {
    .banner-photo {
      padding: 20rem 0;
    }
  }
}

@media screen and (--large) {
  .about {

    #team {

      .wrap {
        lost-center: 100%;
        padding-bottom: 0;
      }

      .members {
        lost-utility: clearfix;
        display: flex;
        flex-wrap: wrap;

        .member {
          lost-column: 1/2 0 0;
          height: 40vw;

          .info-container {

            .bio {
              font-size: 1.5rem !important;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(6) {

            .fa {
              left: calc(50% - 2.25rem);
              transform: rotate(90deg);
            }

            .image-container {
              left: 0;
            }

            .info-container {
              float: right;
            }
          }

          &:nth-child(3),
          &:nth-child(4) {

            .fa {
              left: calc(50% - 1.5rem);
              transform: rotate(-90deg);
            }

            .image-container {
              left: 50%;
            }

            .info-container {
              float: left;
            }
          }
        }
      }
    }
  }
}

@media screen and (--xlarge) {
  .about {

    #timeline {

      .timeline-container {

        .happening {

          &::before {
            left: -2.5rem;
          }

          &:nth-child(2n + 1) {

            &::before {
              right: -3.1rem;
            }
          }
        }
      }
    }

  }
}
