/* Fonts */
$font-heading: 'Noto Sans', Helvetica, sans-serif;
$font-body: 'Open Sans', Helvetica, Arial, sans-serif;
$font-cursive: 'Lora', sans-serif;

/* Media Queries */
@custom-media --small (min-width: 540px);
@custom-media --medium (min-width: 768px);
@custom-media --medium-large (min-width: 1100px);
@custom-media --large (min-width: 1400px);
@custom-media --xlarge (min-width: 1800px);

/* Colors */
$main-primary: #000;
$main-secondary: #7b7b7b;

$accent-primary: #86C54F;
$accent-secondary: #fff;

$grey-primary: #252525;
$grey-secondary: #F5F5F5;
$grey-tertiary: #BEBEBE;

$light-primary: #fff;
$dark-primary: #000;

$menu-primary: $light-primary;
$menu-secondary: $main-primary;

$link-primary: $accent-primary;
$link-secondary: $main-secondary;
