.single-post {

  .post-container {

    .post-img-container {
      margin-bottom: 2rem;
      text-align: center;

      .post-img {
        max-height: 540px;
      }
    }

    .post-meta {
      margin-bottom: 2rem;

      .post-title {
        margin-bottom: 1rem;
      }

      .post-date {
        color: $grey-tertiary;
      }
    }
  }

  .pagination {
    margin-top: 2rem;
    lost-utility: clearfix;

    & > div[class*="pagination-"] {
      lost-column: 1/2;
    }

    .pagination-next {
      text-align: right;
    }
  }


  #sidebar {
    display: none;
    text-align: right;

    .month {
      padding-bottom: 1rem;
      a {
        color: $dark-primary;
      }
    }
  }
}

@media screen and (--small) {
  .single-post {

    #content {
      lost-center: 540px;
    }
  }
}

@media screen and (--medium) {
  .single-post {

    #content: {
      lost-center: 768px;
    }

    .pagination {
      margin-top: 6rem;
    }
  }
}

@media screen and (--medium-large) {
  .single-post {
    clear: fix;
    lost-utility: clearfix;

    #content {
      lost-center: 100%;
    }

    .content-wrapper {
      position: relative;
      lost-column: 2/3;

      &::after {
        position: absolute 2.5% 100%;
        content: '';
        border-right: 2px solid rgba($grey-tertiary, 0.3);
        height: 95%;
      }
    }

    #sidebar {
      lost-column: 1/3;
      display: inline-block;
    }

    .post-container {

      .post-img-container {

        .post-img {
          max-height: 400px;
        }
      }
    }

  }
}
