.products {

  #page-hero {
    background-color: #333;
    background-image: url('/wp-content/themes/jona/dist/img/j-bg-5.jpg');
  }

  #intro {
    text-align: center;
  }

  #products {

    .product {
      transition: 0.3s;

      .fa {
        display: none;
        transition: 0.3s;
      }

      &:not(:last-child) {
        padding-bottom: 6rem;
      }

      .image-container {
        padding: 2rem 0;

        .image {
          background-color: $accent-primary;
          background-size: cover;
          background-position: center;
          padding: 8rem 0;
        }
      }

      .info-container {
        /*float: left;*/

        .info {

          .name {
            text-transform: uppercase;
          }
        }
      }


    }
  }

}

@media screen and (--medium) {
  .products {

    #products {

      .wrap {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        lost-center: 100%;
      }

      .product {
        position: relative;
        lost-utility: clearfix;

        .fa {
          position: absolute;
          display: inline-block;
          left: calc(50% - 2.5rem);
          top: calc(50% - 3rem);
          z-index: 2;
          font-size: 7rem;
          transform: rotate(90deg);
          color: #fff;
        }

        .image-container,
        .info-container {
          lost-column: 1/2 2 0;
        }

        .image-container {
          padding: 0;
          position: absolute 0;
          height: 100%;

          .image {
            position: absolute 0;
          }
        }

        .info-container {
          float: right;
          padding: 3rem;
        }

        &:nth-child(2n + 1) {

          .fa {
            left: calc(50% - 1.5rem);
            transform: rotate(-90deg);
          }

          .info-container {
            float: left;
          }

          .image-container {
            left: 50%;
          }
        }

        &.active {
          background: $accent-primary;
          color: $light-primary;

          .fa {
            color: $accent-primary;
          }

          .info-container {

            .info {

              .btn-std {
                outline: 2px solid $light-primary;
              }
            }
          }

        }
      }
    }
  }
}

@media screen and (--medium-large) {
  .products {

    #products {

      .product {

        .info-container {
          padding: 6rem;

          .info {
            width: 80%;
          }
        }
      }
    }
  }
}
