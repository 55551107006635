.fadeInUp {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  12.5% {
    transform: rotate(10deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  37.5% {
    transform: rotate(0deg);
  }
}
