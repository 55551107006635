.home {
  text-align: center;

  #page-hero {
    background-color: #333;
    background-image: url('/wp-content/themes/jona/dist/img/j-bg-1.jpg');
  }

  #about {
    background-image: url('/wp-content/themes/jona/dist/img/j-bg-2.jpg');
  }

  #products{
    background: $grey-primary;
    padding-bottom: 0;

    .products-container {

      .product {
        position: relative;
        height: 100vw;
        text-align: left;

        .overlay,
        .background {
          position: absolute 0;
          width: 100%;
          height: 100%;
          transition: 0.3s;
        }

        .overlay {
          background: #000;
          opacity: 0.5;
          z-index: 2;
        }

        .background {
          background-position: center;
          background-size: cover;
          z-index: 1;
        }

        .content {
          position: relative;
          padding: 2rem;
          z-index: 2;
          transition: 0.3s;
        }
      }

      .slick-dots {
        bottom: 0;
      }
    }
  }

  #features {

    .feature {

      img {
        width: 8rem;
        height: 8rem;
        margin-bottom: 2rem;
        animation-name: wiggle;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
      }

      &:not(:last-of-type) {
        padding-bottom: 8rem;
      }

    }
  }
}

@media screen and (--small) {
  .home {

    #products {

      .products-container {

        .product {
          height: 50vw;
        }
      }
    }
  }
}

@media screen and (--medium) {
  .home {

    #about {
      .wrap {
        padding-bottom: 20rem;
      }
    }

    #products {

      .products-container {

        .product {
          height: 40vw;

          .content,
          .overlay {
            opacity: 0;
          }

          &:hover {

            .content {
              opacity: 1;
            }

            .overlay {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

@media screen and (--medium-large) {
  .home {

    #products {

      .products-container {

        .product {
          height: 25vw;

          .content {
            padding: 4rem;
          }
        }
      }
    }

    #features {

      .wrap {
        lost-utility: clearfix;
        lost-center: 900px;
      }

      .feature {
        lost-column: 1/3 3 50px;
      }
    }
  }
}
