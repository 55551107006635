.blog {

  #page-hero {
    background-image:  url('/wp-content/themes/jona/dist/img/j-bg-6.jpg');
  }

  #intro {
    text-align: center;
  }

  #posts {
    background-color: $grey-secondary;

    .tease {
      background: $light-primary;
      padding: 2rem;
      margin-bottom: 2rem;
    }
  }

}
