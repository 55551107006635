input,
select,
textarea {
	border: 0;
	background-color: $grey-secondary;
	color: $grey-primary;
	font-size: 18px;
	font-size: 1.8rem;
	font-weight: 400;
	padding: 16px;
	width: 100%;
	border-radius: 0;
}

textarea {
	min-height: 200px;
}

input:focus,
textarea:focus {
	outline: none;
}

input[type="checkbox"],
input[type="image"],
input[type="radio"] {
	width: auto;
}

::placeholder {
	color: $grey-tertiary;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button {
	display: none;
}

form {

	.contact_submit_wrap {
		text-align: center;
	}
}

.sign-up-container {
	padding-top: 2rem;

	input[type="submit"] {
		border: 3px solid $light-primary;
	}
}

.wpcf7-mail-sent-ok {
	border-color: $light-primary !important;
}
