footer {
  width: 100vw;
  text-align: center;
  background: #272727;
  color: $main-secondary;
  flex:none;
  position: relative;

  .widgets {
    padding: 3rem 0;
    lost-utility: clearfix;
  }

  .widgets > .col {
    padding: 4rem 3rem 2rem;

    .title {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    a {
      color: $main-secondary;
      display: inline-block;
      padding: 0.5rem 0;

      &:hover {
        color: $link-primary;
      }
    }

    p {

      &:not(:last-of-type) {
        padding-bottom: 1rem;
      }
    }

    .certification-row {
      padding-top: 2rem;
      lost-utility: clearfix;
      lost-center: 150px;
      li {
        lost-column: 1/2;
      }
    }

    .social-row {
      .social-icon-container {
        li {
          display: inline-block;

          a {
            padding: 1rem;
            font-size: 3rem;
          }
        }
      }
    }
  }

  .detail {
    padding: 2rem;
    border-top: 1px solid rgba($main-secondary, 0.5);

    a {
      color: inherit;
    }
  }
}

@media screen and (--medium) {
	footer {
    text-align: left;

    .widgets > .col {
      lost-column: 1/3 0 0;
      padding: 3rem;

      .certification-row {
        lost-center: none;
        lost-utility: none;

        li {
          lost-column: none;
          display: inline-block;
          padding-right: 2rem;

          img {
            width: 5rem;
          }
        }
      }

      &:last-child {
        lost-column: 1;
        lost-center: 100%;
        text-align: center;
      }
    }

    .detail {
      lost-column: none !important;
      text-align: center;
    }
  }
}

@media screen and (--medium-large) {
	footer {

    .widgets {
      lost-center: 1200px;

      & > .col {
        lost-column: 1/4;

        h4.title {
          font-size: 2.5rem;
        }

        &:last-child {
          lost-column: 1/4;
          lost-center: 100%;
          text-align: left;
        }
      }
    }
  }
}
