.contact {
  text-align: center;

  #page-hero {
    background-color: #333;
    background-image: url('/wp-content/themes/jona/dist/img/j-bg-7.jpg');
  }

  #contact {

    .methods {
      margin-top: 4rem;

      .method {

        img {
          width: 8rem;
          height: 8rem;
          margin-bottom: 2rem;
        }

        &:not(:last-of-type) {
          padding-bottom: 4rem;
        }
      }
    }

  }

  #form {
    lost-utility: clearfix;

    & > .wrap {
      padding-top: 0;
    }
  }

  #map {
    min-height: 300px;
  }

  #staff {

    .col {

      & > div {
        padding-bottom: 4rem;

        .title {
          color: $grey-tertiary;
        }

      }
    }
  }

}

@media screen and (--small) {
  .contact {

    #contact {

      .wrap {
        lost-center: 1100px;
      }

      .methods {
        lost-utility: clearfix;

        .method {
          lost-column: 1/3 0 0;
        }
      }
    }
  }
}

@media screen and (--medium) {
  .contact {

    #contact {

      .wrap {
        padding-bottom: 0 !important;
      }

      .methods {
        margin-top: 8rem;
      }

    }

    #form {

      .wrap {
        padding-bottom: 0;
        lost-center: 1200px;
      }

      h2 {
        display: none;
      }

      form[class*="pirate"] {

        & > div[class*="pirate"] {
          lost-utility: clearfix;

          & > div[class*="pirate"] {
            lost-column: 1/3 0 2rem;
          }
        }
      }
    }

    #staff {
      text-align: left;

      .wrap {
        lost-utility: clearfix;
        lost-center: 1200px;
      }

      .col {
        lost-column: 1/2;

        h2 {
          margin-bottom: 3rem;
        }
      }
    }

    #map {
      height: 600px;
    }
  }
}
